<template>
  <div>
    <v-card class="pa-4">

      <v-card-text>
      <pre style="white-space: pre-wrap;"><h1>Look Here!</h1>
Hi! I am <b>Jarem Thimoty M. Arias</b>, currently a student in the University of the Philippines Los Banos under BS Computer Science and finishing up my Special Problem which is the website you are currently on now!

If you are reading this, it means that hopefully you've agreed to become a tester for my webapp! (Yay!)

Like you, I want to <b>keep the testing short and sweet</b> for everyone so I'll boil down what needs to happen:

<b>OMGenes</b> is a website for <b>viewing and creating VCF Files</b> through an open-source <b>Genome Browser and Variant Caller</b>. I have provided the files needed in the <b>zip file below</b> for testing, you just have to follow the rest of the instructions below (It'll be quick I promise ^^)

For testing, I will be providing <b>Reference Genomes, VCF Files, and Sample Genomes for Yeast and COVID-19 for testing</b>, they will be linked both below and in the instructions.

Below are the <b>full instructions and testing files.</b>

<v-btn color="info" target="_blank" href="https://docs.google.com/document/d/1v7gdd8a9lo00MrjNgnM8Q1lc2xUIUTN9AEWrlAJpfYI/edit?usp=sharing">Testing Instructions</v-btn> <v-btn color="info" target="_blank" href="https://drive.google.com/uc?export=download&id=14ClkiGhT5GNjY_Q6WQz4kU_UnNxcA80x">Test Files Download</v-btn>

<h3>Evaluation</h3>
After testing the different functions, please answer the evaluation form linked below! Thank you so much! If you have your own Genome Files and VCF files you'd like to try out feel free to use OMGenes!

If you have any questions, you may contact me at <b>jmarias@up.edu.ph</b>

<v-btn target="_blank" href="https://forms.gle/dVRzW45ZKcfVH8RMA"  color="info">Evaluation Form</v-btn></pre>
      </v-card-text>
      
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'HomeView'
}
</script>
